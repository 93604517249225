table.dataTable {
    background-color: #fafafa !important; /* Light gray background */
    table-layout: fixed;
    width: 100%;
}

th,
td {
    white-space: nowrap;
}

div.dt-container {
    width: 100%;
    margin: 0 auto;
}

div.dt-container .dt-search input {
    /* Styles for the input element with class dt-input */
    background-color: #fafafa;
}

div.dt-container .dt-input {
    background-color: #fafafa;
}

/* General background color for all paging buttons */
button.dt-paging-button {
    background-color: var(--green-30) !important;
    color: var(--neutral-05) !important;
}

button.dt-paging-button:hover {
    background-color: var(--green-90) !important;
    color: var(--neutral-05) !important;
}

button.dt-paging-button.current {
    background-color: var(--green-40) !important;
    color: var(--neutral-05) !important;
}

button.dt-paging-button.first,
button.dt-paging-button.last {
    background-color: var(--green-60) !important;
    color: var(--neutral-05) !important;
}

button.dt-paging-button.next,
button.dt-paging-button.previous {
    background-color: var(--green-50) !important;
    color: var(--neutral-05) !important;
}

button.dt-paging-button.disabled {
    background-color: var(--green-10) !important;
    color: var(--neutral-05) !important;
}

.dt-scroll-body {
    scrollbar-width: thin;
    scrollbar-color: #a3a3a3 #f0f0f0;
}

button.dt-button {
    background-color: var(--neutral-05);
    color: var(--neutral-90);
    border: 1px solid var(--neutral-80);
    border-radius: 4px;
    padding: 0.25rem 1rem;
}

button.dt-button:hover {
    background-color: var(--green-70);
    color: var(--neutral-05);
}

button.dt-button:active {
    background-color: var(--green-90);
    color: var(--neutral-05);
}
